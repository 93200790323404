<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">RESULTADOS DE LAS ENCUESTAS DE SATISFACCIÓN REALIZADAS:
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de satisfaccion de clientes</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL ÁREA DE SATISFACCIÓN DE CLIENTES:
                    </span>

                    <hr>
                    <b-row class="content-justify-center text-center">
                        <b-col cols="6" class=" my-2 icon-custom vr">
                            <i style="inline-size: auto" class=" ico fas fa-edit fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaSatisfacciones.length}}</span>
                            <br>
                            <span class="text-muted">Cuestionarios registrados</span>
                        </b-col>
                        <b-col cols="6" class="text-center my-2 icon-custom">
                            <i style="inline-size: auto" class=" ico fas fa-star-half-alt fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{porcentaEvaluacion}}%</span>
                            <br>
                            <span class="text-muted">Evaluación media</span>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row class="text-center justify-content-center">

                        <b-col md="6" v-if="checkPermissions('010-MEJ-SAT','c')==1"  class="my-2">
                            <b-button block size="md" variant="custom" @click="modalEnvioCuestionario = true">
                                <i style="inline-size: auto" class="fas fa-envelope fa-3x pb-1"></i><br>Envio
                            </b-button>
                        </b-col>
                        <b-col md="6"  v-if="checkPermissions('010-MEJ-SAT-CON','r')==1"  class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Configuracion Satisfaccion'}">
                                <i style="inline-size: auto" class="fas fa-question-circle fa-3x pb-1"></i><br>Configurar preguntas
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-external-link-alt fa-md"></i><span class="h5"> Enlace a cuestionario</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">Proporcione el siguiente enlace a sus clientes para que accedan a su cuestionario:
                        <a target="_blank" :href="`${path}cuestionarios/satisfaccion-del-cliente/ejemplo/${datosEnvioCuestionario.idCliente}`">Demostración de cuestionario</a></span>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Cuestionarios de satisfacción registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table hover bordered show-empty mediun responsive outlined :items="listaSatisfacciones" :fields="camposEvaluaciones" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button :to="{name:'Gestion Satisfaccion Clientes', params: {id: param.item.idSatisfaccionCliente }}" size="sm" v-c-tooltip.hover.click="'Ver detalles'" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button :disabled="disabled" @click="generarReporte(param)" size="sm" v-c-tooltip.hover.click="'Descargar'" variant="blue" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-download my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <!-- <b-button v-if="param.item.urlReporte !=null" @click="descargarDocumento(param.item.urlReporte)" size="sm" v-c-tooltip.hover.click="'Descargar'" variant="success" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-download my-0 mx-0"></i>
                                        </span>
                                    </b-button> -->
                                    <b-button  v-if="checkPermissions('010-MEJ-SAT','d')==1" @click="eliminarSatisfaccion(param)" size="sm" v-c-tooltip.hover.click="'Eliminar'" variant="danger" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalEnvioCuestionario" :closeOnBackdrop="false" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-envelope fa-md"></i>
                <span class="h5"> Gestión de clientes</span></h6>
            <CButtonClose @click="modalEnvioCuestionario = false" class="text-white" />
        </template>
        <b-row>
            <b-col md="12">
                <b-form-group label="Clientes:">

                    <span v-for="items in optionsClientes" :key="items.index">
                        <b-form-checkbox plain stacked v-model="datosEnvioCuestionario.clientesSeleccionados" :value="items">
                            {{items.razonSocial}} <span class="text-muted ml-2" v-if="items.fecha != null">Último envio: {{items.fecha}}</span></b-form-checkbox>
                    </span>
                </b-form-group>
            </b-col>
            <b-col lg="12" class="text-right my-2">
                <b-button size="md" variant="success" class=" mr-2" @click="enviarCorreos()">
                    Enviar
                </b-button>
                <b-button size="md" variant="danger" @click="modalEnvioCuestionario = false">
                    Cancelar
                </b-button>
            </b-col>
        </b-row>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import {
    CChartPie
} from '@coreui/vue-chartjs'

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";


export default {
    components: {
        CChartPie
    },
    data() {
        return {
            path: CONSTANTES.URL_RUTA_FRONTEND+'#/',
            disabled: false,
            listaPreguntasSatisfaccion: [],
            datosEnvioCuestionario: {
                idCliente: '',
                clientesSeleccionados: [],
            },
            labelEstadistica: ["Muy mal", "Mal", "Regular", "Bien", "Excelente"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            optionsClientes: [],

            listaSatisfacciones: [],
            camposEvaluaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Cliente",
                    class: "text-center"
                },
                {
                    key: "valoracion",
                    label: "Valoración",
                    class: "text-center"
                },
                {
                    key: "fechaCompletado",
                    label: "Fecha",
                    class: "text-center"
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                },
            ],
            modalEnvioCuestionario: false,
            porcentaEvaluacion: 0,
            datosEstadistica: {
                muyMal: 0,
                mal: 0,
                regular: 0,
                bien: 0,
                excelente: 0,
            }

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        registrarIncidencia() {},
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        listarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-clientes-correo", {
                        params: {
                            idCliente: me.datosEnvioCuestionario.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.optionsClientes = response.data.filter(x => x.email != '');
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        convertImgUrlToBase64(url) {
            let me = this;
            var image = new Image();
            // image.crossOrigin = "Anonymous";
            image.crossOrigin = "Use-Credentials";

            // image.setAttribute('crossOrigin', 'anonymous'); // use it if you try in a different origin of your web
            image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                canvas.getContext('2d').drawImage(this, 0, 0);

                canvas.toBlob(
                    function (source) {
                        var newImg = document.createElement("img"),
                            url = URL.createObjectURL(source);

                        newImg.onload = function () {
                            URL.revokeObjectURL(url);
                        };

                        newImg.src = url;
                    },
                    "image/jpeg",
                    1
                );
            
              let dataUrl = canvas.toDataURL("image/jpeg");
                 me.logo = dataUrl;
            };
            image.src = url;
        },

        generarReporte(param) {
            let me = this;
            me.disabled = true;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-logo-cliente", {
                        params: {
                            // idCliente: me.datosEnvioCuestionario.idCliente,
                            logo: JSON.parse(localStorage.settings).logo
                        }

                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let listaPreguntas = me.listaPreguntasSatisfaccion.filter(x => x.idSatisfaccionCliente == param.item.idSatisfaccionCliente)

                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}plantillas/plantilla-satisfaccion-cliente.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [150, 60];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .attachModule(imageModule);
                        doc.setData({
                            logo: response.data,
                            fecha: param.item.fechaCompletado,
                            cliente: param.item.razonSocial,
                            comentario: param.item.observacion,
                            lista: listaPreguntas,
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "satisfaccion-cliente.docx");
                        //me.disabled = false;
                    });

                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        descargarDocumento(url) {
            window.open(url)
        },
        listarSatifacciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-satisfacciones-cliente", {
                        params: {
                            idCliente: me.datosEnvioCuestionario.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaSatisfacciones = response.data;
                    me.filasTotales = me.listaSatisfacciones.length
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerPreguntasEvaluacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-preguntas-satisfaccion-cliente", {
                        params: {
                            idCliente: me.datosEnvioCuestionario.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    for (let i in response.data) {
                        me.listaPreguntasSatisfaccion.push({
                            idSatisfaccionCliente: response.data[i].idSatisfaccionCliente,
                            pregunta: response.data[i].pregunta,
                            tipoPregunta: response.data[i].tipoPregunta,
                            valoracionNumero: response.data[i].valoracion,
                            valoracion: response.data[i].tipoPregunta == 2 ? parseInt(response.data[i].valoracion) + ' Estrellas' : parseInt(response.data[i].valoracion) == 1 ? 'SI ' : 'NO',
                            nombreArchivo: response.data[i].urlArchivo ? response.data[i].nombreArchivo : ' ',
                        })
                        
                    }
                    me.calcularDatosEstadisticos()
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = true;
                });
        },
        calcularDatosEstadisticos(){
            let me = this;
            let puntaje=0;
            let puntajeTotal=0;
            for (const i in me.listaPreguntasSatisfaccion) {
                if(me.listaPreguntasSatisfaccion[i].tipoPregunta==1){
                    if(me.listaPreguntasSatisfaccion[i].valoracionNumero==1){
                        puntaje += 5;
                        me.datosEstadistica.excelente += 1;
                    }else{
                        puntaje += 1;
                        me.datosEstadistica.muyMal += 1;
                    }
                }else{
                    puntaje += parseInt(me.listaPreguntasSatisfaccion[i].valoracionNumero);
                    if(me.listaPreguntasSatisfaccion[i].valoracionNumero==1){
                        me.datosEstadistica.muyMal += 1;
                    }
                    if(me.listaPreguntasSatisfaccion[i].valoracionNumero==2){
                        me.datosEstadistica.mal += 1;
                    }
                    if(me.listaPreguntasSatisfaccion[i].valoracionNumero==3){
                        me.datosEstadistica.regular += 1;
                    }
                    if(me.listaPreguntasSatisfaccion[i].valoracionNumero==4){
                        me.datosEstadistica.bien += 1;
                    }
                    if(me.listaPreguntasSatisfaccion[i].valoracionNumero==5){
                        me.datosEstadistica.excelente += 1;
                    }
                }
                puntajeTotal += 5;
            }
            me.porcentaEvaluacion = (puntaje / puntajeTotal * 100).toFixed(2);
        },
        enviarCorreos() {
            if (this.datosEnvioCuestionario.clientesSeleccionados.length < 1) {
                this.swat('error', 'Debes seleccionar al menos un cliente')
                return;
            } else {
                let me = this;
                axios
                    .post(
                        CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-satisfaccion-cliente",
                        me.datosEnvioCuestionario, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`
                            },
                        }
                    )
                    .then(function (response) {
                        me.modalEnvioCuestionario = false
                        let color = response.data.resultado == 1 ? "success" : "error";
                        me.swat(color, response.data.mensaje)
                        me.listarClientes();
                    })
                    .catch(function (error) {
                        me.swat('error', 'Algo salió mal!')
                    });
            }
        },
        eliminarSatisfaccion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el cuestionario?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-satisfaccion-cliente", {
                                idSatisfaccionCliente: param.item.idSatisfaccionCliente
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarSatifacciones();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        resetModalEnvioCuestionario() {
            this.datosEnvioCuestionario.clientesSeleccionados = [];
            this.modalEnvioCuestionario = false
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 1,
                borderWidth: 3,
                backgroundColor: [
                    '#f16553d4',
                    '#fdcd60cc',
                    '#ccccccdb',
                    '#6bba70db',
                    '#428bca94',
                ],
                data: [this.datosEstadistica.muyMal, this.datosEstadistica.mal, this.datosEstadistica.regular, this.datosEstadistica.bien, this.datosEstadistica.excelente]
            }]
        },

    },
    watch: {
        modalEnvioCuestionario: function (val) {
            if (val == false) {
                this.resetModalEnvioCuestionario();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosEnvioCuestionario.idCliente = user.uidClient;
            this.listarClientes();
            this.listarSatifacciones();
            this.obtenerPreguntasEvaluacion();
          }
    }

}
</script>
